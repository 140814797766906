.services {
  padding: 80px 0;
  background-color: #f9f9f9; /* Light background for contrast */
  text-align: center;
}

.services h2 {
  font-size: 2.5rem;
  margin-bottom: 40px;
  color: #333; /* Dark text color */
}

.service-grid {
  display: flex;
  flex-wrap: wrap; /* Allow wrapping for responsiveness */
  justify-content: center; /* Center items */
  gap: 30px; /* Space between items */
}

.service {
  background: white; /* White background for each service box */
  border-radius: 10px; /* Rounded corners */
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  padding: 30px; /* Inner padding */
  transition: transform 0.3s; /* Animation on hover */
  flex: 1 1 300px; /* Allow flexible growth */
  max-width: 350px; /* Limit max width */
}

.service:hover {
  transform: translateY(-5px); /* Lift effect on hover */
}

.service h3 {
  font-size: 1.8rem; /* Larger service title */
  margin-bottom: 15px; /* Space below title */
  color: #007bff; /* Branding color */
}

.service p {
  font-size: 1.2rem; /* Readable text size */
  color: #666; /* Softer text color */
}

/* General header styling */
header {
    background-color: #1a1a1a; /* Dark background for a sleek modern look */
    padding: 15px 0;
    position: sticky;
    top: 0;
    z-index: 1000; /* Keeps header on top */
    width: 100%;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Adds subtle shadow */
}

/* Container inside header */
header .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
}

/* Logo styling */
header .logo h1 {
    color: #fff;
    font-size: 1.8em;
    font-weight: bold;
    margin: 0;
    letter-spacing: 2px;
}

/* Navigation menu styling */
header nav ul {
    list-style: none;
    display: flex;
    gap: 30px;
    margin: 0;
    padding: 0;
}

/* Individual links in the menu */
header nav ul li a {
    color: #fff;
    text-decoration: none;
    font-size: 1em;
    font-weight: 500;
    padding: 10px 15px;
    transition: all 0.3s ease-in-out;
    border-radius: 5px;
}

/* Hover effect on menu items */
header nav ul li a:hover {
    background-color: #f39c12; /* Accent color on hover */
    color: #1a1a1a; /* Text changes on hover */
}

/* Responsive Design for smaller screens */
@media (max-width: 768px) {
    header nav ul {
        flex-direction: column;
        background-color: #333;
        padding: 20px;
        position: absolute;
        right: 0;
        top: 60px;
        width: 100%;
        display: none;
    }
    header nav ul.active {
        display: block;
    }
    
    header .container {
        flex-direction: column;
        align-items: flex-start;
    }

    .menu-toggle {
        display: block;
        cursor: pointer;
        color: white;
        font-size: 1.5em;
    }
}
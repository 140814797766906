.hero {
  background-color: #f7f7f7; /* Fallback color */
  background-size: cover; /* Ensures the image covers the section */
  background-position: center; /* Center the image */
  padding: 100px 0; /* Top and bottom padding */
  text-align: left; /* Align text to the left */
}

.hero .container {
  display: flex;
  justify-content: flex-start; /* Align items to the start */
  align-items: center; /* Center items vertically */
  padding-left: 70px; /* Add padding from the left */
  height: 100%; /* Full height for vertical centering */
}

.hero-text {
  max-width: 600px; /* Limit the width of the text */
  color: black; /* Text color */
  display: flex;
  flex-direction: column; /* Stack elements vertically */
  align-items: flex-start; /* Align text to the start */
}

.hero h2 {
  font-size: 3.1rem; /* Size of the heading */
  margin-bottom: 20px; /* Space below the heading */
}

.hero p {
  font-size: 1.8rem; /* Size of the paragraph */
  margin-bottom: 20px; /* Space below the paragraph */
}
.cta {
  background-color: #007bff; /* Button color */
  color: white; /* Button text color */
  padding: 10px 20px; /* Button padding */
  text-decoration: none; /* No underline */
  border-radius: 5px; /* Rounded corners */
  transition: background-color 0.3s; /* Transition effect */
  align-self: center; /* Center the button */
  margin-top: 20px; /* Space above the button */
}

.cta:hover {
  background-color: #0056b3; /* Darker button color on hover */
}

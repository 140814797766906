/* General footer styling */
footer {
    background-color: #1a1a1a; /* Dark background for sleek look */
    padding: 40px 0; /* Large padding for spaciousness */
    color: #fff;
    text-align: center; /* Center align text */
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.2); /* Adds a soft shadow */
}

/* Container inside footer */
footer .container {
    max-width: 1200px;
    margin: 0 auto;
}

/* Social media icons */
footer ul.social {
    list-style: none;
    display: flex;
    justify-content: center;
    padding: 0;
    gap: 30px; /* More spacing between icons */
    margin-bottom: 20px; /* Adds space between social and copyright */
}

/* Social media links */
footer ul.social li a {
    color: white;
    font-size: 1.5em; /* Larger font for icons */
    text-decoration: none;
    transition: all 0.3s ease;
}

/* Hover effect on social media icons */
footer ul.social li a:hover {
    color: #f39c12; /* Accent color for hover effect */
}

/* Copyright text */
footer p {
    font-size: 0.9em;
    color: #bbb; /* Subtle gray for copyright */
}

/* Responsive footer for smaller devices */
@media (max-width: 768px) {
    footer ul.social {
        flex-direction: column;
        gap: 15px; /* Smaller gap on mobile */
    }

    footer p {
        font-size: 0.8em;
    }
}
